import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import GiveContentTrans from '../components/GiveContentTrans';

export default function HomepageTestimonials() {
  return (
    <div className="testimonials inner-grid">
      <div className="content-block">
        <h2>
          <GiveContentTrans language="et">Klientide tagasiside</GiveContentTrans>
          <GiveContentTrans language="en">Client feedback</GiveContentTrans>
        </h2>

        <p>
          <GiveContentTrans language="et">
            Aus värk. Parim viis veenduda, et asjad ka tegelikult nii on, tuleb rahvatarkusest: "Usalda, aga kontrolli".
          </GiveContentTrans>
          <GiveContentTrans language="en">
            Trust, but verify. Give us a try and find out for yourself why our testimonials are the real deal.
          </GiveContentTrans>
        </p>
      </div>

      <div className="feedback-container">
      <GiveContentTrans language="et">
        <div className="testimonial-card">
          <StaticImage
            src="../assets/davidshaw.jpg"
            alt="David Shaw"
            placeholder="blurred"
            width={64}
            height={64}
            layout="fixed"
            className="testimonial-image"
          />
          <div className="testimonial-content">
            <p className="testimonial-text">
            (Tõlge) Olen koostööd teinud Taneliga juba aasta aega ning tema pühendumus erakordsete kasutajakogemuste loomisele on enneolematu. Alates disainiprotsessi algusest kuni toote turuletoomiseni on ta uskumatult tähelepanelik detailide üle. Tulemus ületab ootusi.

Üks asju, mis teda teistest eristab, on tema võime mõista oma klientide vajadusi ja eesmärke. Ta võtab aega sihtgrupi põhjalikuks uurimiseks ja mõistmiseks ning kasutab seda intuitiivsete ja kasutajasõbralike disainide loomiseks.

Soovitan Tanelit kõigile, kes otsivad oma toote või teenuse kasutajakogemuse taset tõstvat partnerit. Ta on tõesti tipptasemel ja ma pole kahtlust, et ta toob erakordseid tulemusi ka teistele klientidele.
            </p>
            <div className="testimonial-author">
              <h4>David Shaw</h4>
              <p>Cedara CEO</p>
            </div>
          </div>
        </div>

  
        <div className="testimonial-card">
          <StaticImage
            src="../assets/testperson.jpg"
            alt="Kliendi Nimi"
            placeholder="blurred"
            width={64}
            height={64}
            layout="fixed"
            className="testimonial-image"
          />
          <div className="testimonial-content">
            <p className="testimonial-text">
            Olen Taneliga teinud mitmeid projekte. Oleme mõõtnud oma Ärilaenu kampaaniaid ja meie conversion rate on 5x turu keskmisest parem. Kas see tähendab, et ma kulutan 5x vähem raha reklaamile? Ei, see tähendab, et me teenime 5x rohkem kasumit. See võtab minult pinget maha.            </p>
            <div className="testimonial-author">
              <h4>Mario Märtson</h4>
              <p>CMO</p>
            </div>
          </div>
        </div>
        </GiveContentTrans>

        <GiveContentTrans language="en">
        <div className="testimonial-card">
          <StaticImage
            src="../assets/davidshaw.jpg"
            alt="David Shaw"
            placeholder="blurred"
            width={64}
            height={64}
            layout="fixed"
            className="testimonial-image"
          />
          <div className="testimonial-content">
            <p className="testimonial-text">
            I have been working with Tanel for the past year and his dedication to creating exceptional user experiences is unmatched. From the beginning of the design process to the final product launch, he is incredibly attentive to detail and works tirelessly to ensure that the end result exceeds expectations.

One of the things that sets him apart is his ability to understand the needs and goals of his clients. He takes the time to thoroughly research and understand the target audience, and use this knowledge to create intuitive and user-friendly designs.

I highly recommend Tanel to anyone looking to elevate the user experience of their product or service. He is truly top-notch and I have no doubt that they will continue to deliver exceptional results for their clients.
            </p>
            <div className="testimonial-author">
              <h3>David Shaw</h3>
              <p>Cedara CEO</p>
            </div>
          </div>
        </div>

  
        <div className="testimonial-card">
          <StaticImage
            src="../assets/testperson.jpg"
            alt="Kliendi Nimi"
            placeholder="blurred"
            width={64}
            height={64}
            layout="fixed"
            className="testimonial-image"
          />
          <div className="testimonial-content">
            <p className="testimonial-text">
            I have worked on multiple projects with Tanel. We have measured Ärilaen campaigns and our conversion rate is 5 times better than the market average. Does this mean that I am spending 5 times less money on advertising? No, it means that we are earning 5 times more profit. This takes the pressure off of me.            </p>
            <div className="testimonial-author">
            <h3>Mario Märtson</h3>
              <p>CMO</p>
            </div>
          </div>
        </div>
        </GiveContentTrans>



      </div>
    </div>
  );
}
